<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <div v-if="$can('READ', 'worker-tax-deduction') || $can('WRITE', 'worker-tax-deduction')">
            <!-- Page header -->
            <div class="uk-flex uk-flex-middle uk-flex-between">
                <p
                    v-if="$can('READ', 'worker-tax-deduction')"
                    class="uk-padding-remove uk-margin-remove uk-text-bold"
                >Tax Deduction Proof List</p>
                <button
                    v-if="$can('WRITE', 'worker-tax-deduction')"
                    class="uk-button uk-button-primary uk-border-rounded uk-flex uk-flex-middle"
                    @click="createTemplate"
                >
                    <img :src="`${images}/plus-circle-white.svg`" alt="Download icon">
                    <span class="uk-margin-small-left uk-text-bold">Create Template</span>
                </button>
            </div>
            <!-- End page header -->

            <LoaderPage v-if="is_loading" />

            <!-- Tax deduction batch list table -->
            <div v-else class="uk-overflow-auto uk-margin-top uk-card-default">
                <table class="uk-table uk-table-medium uk-table-striped uk-table-middle">
                    <thead>
                        <tr>
                            <th class="uk-text-center uk-text-nowrap">No</th>
                            <th class="uk-text-center uk-text-nowrap">Template Name</th>
                            <th class="uk-text-center uk-text-nowrap">Type</th>
                            <th class="uk-text-center uk-text-nowrap">Month</th>
                            <th class="uk-text-center uk-text-nowrap">Year</th>
                            <th class="uk-text-center uk-text-nowrap">Number of Mitra</th>
                            <th class="uk-text-center uk-text-nowrap">Number of Proof Sent</th>
                            <th
                                v-if="$can('READ', 'worker-tax-deduction') || $can('DELETE', 'worker-tax-deduction')" 
                                class="uk-text-center uk-text-nowrap uk-table-shrink"
                            >Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="tax_deduction_batch_list !== null && tax_deduction_batch_list.docs.length > 0">
                        <tr v-for="(tax_deduction, index) in  tax_deduction_batch_list.docs" :key="index">
                            <td class="uk-text-center uk-text-nowrap">{{ setNumberList({ index }) }}</td>
                            <td
                                class="uk-text-center uk-text-nowrap uk-text-primary"
                                style="cursor: pointer;"
                                @click="goToTaxDetail({ taxId: tax_deduction._id })"
                            >{{ tax_deduction.name || '-' }}</td>
                            <td class="uk-text-center uk-text-nowrap">{{ tax_deduction.type === 1 ? 'Yearly' : tax_deduction.type === 0 ? 'Monthly' : 'New Monthly' }}</td>
                            <td class="uk-text-center uk-text-nowrap">
                                {{ tax_deduction.type === 1 ?
                                    `${monthFormatter({ index: tax_deduction.month })}-${monthFormatter({ index: tax_deduction.month_end })}`
                                    : monthFormatter({ index: tax_deduction.month }) }}
                            </td>
                            <td class="uk-text-center uk-text-nowrap">{{ tax_deduction.year || '-' }}</td>
                            <td class="uk-text-center uk-text-nowrap">{{ tax_deduction.count_mitra || 0 }}</td>
                            <td class="uk-text-center uk-text-nowrap">{{ tax_deduction.count_mitra_distributed || 0 }}</td>
                            <td
                                v-if="$can('READ', 'worker-tax-deduction') || $can('DELETE', 'worker-tax-deduction')"
                                class="uk-text-center uk-text-nowrap"
                            >
                                <button
                                    v-if="$can('EDIT', 'worker-tax-deduction')"
                                    type="button"
                                    class="uk-button uk-border-rounded uk-button-primary"
                                    @click="distributeBatch({ taxId: tax_deduction._id })"
                                >Blast to Mitra</button>
                                <button
                                    v-if="$can('DELETE', 'worker-tax-deduction')"
                                    type="button"
                                    class="uk-button uk-border-rounded uk-button-danger uk-margin-small-left"
                                    @click="deleteTemplate({ taxId: tax_deduction._id })"
                                >Delete</button>
                            </td>
                        </tr>
                    </tbody>
                    <EmptyTable v-else :colspan="8" empty-text="No entries found" />
                </table>
                <pagination
                    v-if="tax_deduction_batch_list !== null && tax_deduction_batch_list.totalDocs > 0"
                    :total-data="tax_deduction_batch_list.totalDocs"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                />
            </div>
            <!-- End tax deduction batch list table -->

            <!-- Create tax deduction batch modal box -->
            <div id="create-template" class="create-template" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button
                        type="button"
                        class="uk-modal-close-default"
                        @click="hideCreateTemplateModal"
                        uk-close
                    ></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-margin-remove">Create Tax Deduction Proof Template</h2>
                    </div>
                    <form @submit.prevent="doCreateTemplate">
                        <div class="uk-modal-body">
                            <div v-if="form.type === null" class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <p>Please select the type of the Tax Deduction Proof.</p>
                                    <label
                                        for="templateName"
                                        class="uk-form-label"
                                    >Type</label>
                                    <select
                                        id="taxBatchType"
                                        name="taxBatchType"
                                        class="uk-select uk-border-rounded"
                                        v-model="form.type"
                                    >
                                        <option value=null disabled>Select tax deduction type</option>
                                        <option value="0">Monthly</option>
                                        <option value="1">Yearly</option>
                                        <option value="2">New Monthly 2024</option>
                                    </select>
                                </div>
                            </div>
                            <template v-else>
                                <div v-if="form.type === '0'" class="uk-grid-small" uk-grid>
                                    <div class="uk-width-1-1">
                                        <label
                                            for="templateName"
                                            class="uk-form-label"
                                        >Template name</label>
                                        <input
                                            id="templateName"
                                            type="text"
                                            class="uk-input uk-border-rounded"
                                            placeholder="Template name"
                                            v-model="form.name"
                                        >
                                    </div>
                                    <div class="uk-width-1-2@s">
                                        <label
                                            for="month"
                                            class="uk-form-label"
                                        >Month</label>
                                        <select
                                            id="month"
                                            name="month"
                                            class="uk-select uk-border-rounded"
                                            v-model="form.month"
                                        >
                                            <option
                                                v-for="(month, index) in month_list"
                                                :key="index"
                                                :value="month.value"
                                            >{{ month.label }}</option>
                                        </select>
                                    </div>
                                    <div class="uk-width-1-2@s">
                                        <label
                                            for="year"
                                            class="uk-form-label"
                                        >Year</label>
                                        <select
                                            id="year"
                                            name="year"
                                            class="uk-select uk-border-rounded"
                                            v-model="form.year"
                                        >
                                            <option
                                                v-for="(year, index) in year_list"
                                                :key="index"
                                                :value="year"
                                            >{{ year }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div v-else-if="form.type == 1" class="uk-grid-small" uk-grid>
                                    <div class="uk-width-1-1">
                                        <label
                                            for="templateName"
                                            class="uk-form-label"
                                        >Template name</label>
                                        <input
                                            id="templateName"
                                            type="text"
                                            class="uk-input uk-border-rounded"
                                            placeholder="Template name"
                                            v-model="form.name"
                                        >
                                    </div>
                                    <div class="uk-width-1-2@s">
                                        <label
                                            for="month"
                                            class="uk-form-label"
                                        >Month (from)</label>
                                        <select
                                            id="month"
                                            name="month"
                                            class="uk-select uk-border-rounded"
                                            v-model="form.month"
                                        >
                                            <option
                                                v-for="(month, index) in month_list"
                                                :key="index"
                                                :value="month.value"
                                            >{{ month.label }}</option>
                                        </select>
                                    </div>
                                    <div class="uk-width-1-2@s">
                                        <label
                                            for="monthTo"
                                            class="uk-form-label"
                                        >Month (to)</label>
                                        <select
                                            id="monthTo"
                                            name="monthTo"
                                            class="uk-select uk-border-rounded"
                                            v-model="form.month_end"
                                        >
                                            <option
                                                v-for="(month, index) in month_list"
                                                :key="index"
                                                :value="month.value"
                                                :disabled="month.value <= form.month"
                                                :class="{'uk-text-muted': month.value <= form.month}"
                                            >{{ month.label }}</option>
                                        </select>
                                    </div>
                                    <div class="uk-width-1-2@s">
                                        <label
                                            for="year"
                                            class="uk-form-label"
                                        >Year</label>
                                        <select
                                            id="year"
                                            name="year"
                                            class="uk-select uk-border-rounded"
                                            v-model="form.year"
                                        >
                                            <option
                                                v-for="(year, index) in year_list"
                                                :key="index"
                                                :value="year"
                                            >{{ year }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div v-if="form.type === '2'" class="uk-grid-small" uk-grid>
                                    <div class="uk-width-1-1">
                                        <label
                                            for="templateName"
                                            class="uk-form-label"
                                        >Template name</label>
                                        <input
                                            id="templateName"
                                            type="text"
                                            class="uk-input uk-border-rounded"
                                            placeholder="Template name"
                                            v-model="form.name"
                                        >
                                    </div>
                                    <div class="uk-width-1-2@s">
                                        <label
                                            for="month"
                                            class="uk-form-label"
                                        >Month</label>
                                        <select
                                            id="month"
                                            name="month"
                                            class="uk-select uk-border-rounded"
                                            v-model="form.month"
                                        >
                                            <option
                                                v-for="(month, index) in month_list"
                                                :key="index"
                                                :value="month.value"
                                            >{{ month.label }}</option>
                                        </select>
                                    </div>
                                    <div class="uk-width-1-2@s">
                                        <label
                                            for="year"
                                            class="uk-form-label"
                                        >Year</label>
                                        <select
                                            id="year"
                                            name="year"
                                            class="uk-select uk-border-rounded"
                                            v-model="form.year"
                                        >
                                            <option
                                                v-for="(year, index) in year_list"
                                                :key="index"
                                                :value="year"
                                            >{{ year }}</option>
                                        </select>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                v-if="!button_loading"
                                type="button"
                                class="uk-button uk-button-default myrobin-border-blue myrobin-color-blue"
                                @click="hideCreateTemplateModal"
                            >Cancel</button>
                            <button
                                v-if="!button_loading"
                                type="submit"
                                class="uk-button uk-margin-small-left"
                                :class="[disableCreateBatchButton ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="disableCreateBatchButton"
                            >Create</button>
                            <button
                                v-if="button_loading"
                                type="button"
                                class="uk-button uk-button-default"
                                disabled
                            >
                                <span><div uk-spinner="ratio: 0.5"></div></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End create tax deduction batch modal box -->

            <!-- Delete tax deduction batch modal box -->
            <div v-if="$can('DELETE', 'worker-tax-deduction')" id="delete-tax-deduction" class="delete-tax-deduction" esc-close="false" bg-close="false" uk-modal>
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button
                        type="button"
                        class="uk-modal-close-default"
                        @click="hideDeleteTaxModal"
                        uk-close
                    ></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-medium">Confirmation</h2>
                    </div>
                    <div class="uk-modal-body">
                        <p>Are you sure want to delete this entry?</p>
                    </div>
                    <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                        <button
                            v-if="!button_delete_loading"
                            type="button"
                            class="uk-button uk-button-default uk-modal-close myrobin-color-blue myrobin-border-blue"
                            @click="hideDeleteTaxModal"
                        >No</button>
                        <button
                            v-if="!button_delete_loading"
                            type="button"
                            class="uk-button uk-button-default uk-margin-small-left uk-button-primary"
                            @click="doDeleteTax"
                        >Yes</button>
                        <button
                            v-if="button_delete_loading"
                            type="button"
                            class="uk-button uk-button-default"
                            disabled
                        >
                            <span><div uk-spinner="ratio: 0.5"></div></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- End delete tax deduction batch modal box -->

            <!-- Loading process modal box -->
            <div id="processing-modal" class="processing-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog">
                    <div class="uk-modal-body">
                        <div class="uk-flex uk-flex-column uk-flex-middle uk-flex-center">
                            <div uk-spinner="ratio: 2"></div>
                            <div class="uk-margin-top">Please wait...</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end loading process modal box -->
        </div>

        <!-- Handler when user can't access this page -->
        <div v-else>
            <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                    <span class="uk-text-large uk-text-bold">
                        <p>You are not authorized for read data this page</p>
                    </span>
                </div>
            </div>
        </div>
        <!-- End handler when user can't access this page -->
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PREFIX_IMAGE } from "@/utils/constant";
import { 
    notificationSuccess, 
    notificationDanger, 
    notificationDangerCustom, 
} from '@/utils/notification';
import formatter from '@/utils/formatter';

export default {
    name: 'TaxDeductionBatchList',
    components: {
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        Pagination: () => import('@/components/globals/Pagination')
    },
    data() {
        return {
            is_loading: false,
            images: PREFIX_IMAGE,
            month_list: [
                {
                    value: 1,
                    label: 'January'
                },
                {
                    value: 2,
                    label: 'February'
                },
                {
                    value: 3,
                    label: 'March'
                },
                {
                    value: 4,
                    label: 'April'
                },
                {
                    value: 5,
                    label: 'May'
                },
                {
                    value: 6,
                    label: 'June'
                },
                {
                    value: 7,
                    label: 'July'
                },
                {
                    value: 8,
                    label: 'August'
                },
                {
                    value: 9,
                    label: 'September'
                },
                {
                    value: 10,
                    label: 'October'
                },
                {
                    value: 11,
                    label: 'November'
                },
                {
                    value: 12,
                    label: 'December'
                }
            ],
            year_list: [],
            form: {
                name: null,
                month: null,
                year: null,
                type: null,
                month_end: null,
            },
            button_loading: false,
            tax_deduction_batch_list: null,
            meta: {
                limit: 10,
                page: 1
            },
            selected_tax_deduction_batch_id: null,
            button_delete_loading: false
        };
    },
    computed: {
        disableCreateBatchButton() {
            return (
                this.form.name === null || 
                this.form.month === null || 
                this.form.year === null
            );
        }
    },
    watch: {
        async meta() {
            try {
                await this.getTaxList();
            } catch (error) {
                notificationDanger(error);
            }
        },
    },
    beforeMount() {
        if (window.UIkit.modal('.create-template')) window.UIkit.modal('.create-template').$destroy(true);
        if (window.UIkit.modal('.delete-tax-deduction')) window.UIkit.modal('.delete-tax-deduction').$destroy(true);
        if (window.UIkit.modal('.processing-modal')) window.UIkit.modal('.processing-modal').$destroy(true);
    },
    async mounted() {
        try {
            this.is_loading = true;
            await this.getTaxList();
            this.generateArrayOfYears();
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.is_loading = false;
        }
    },
    methods: {
        ...mapActions({
            getTaxBatchList: 'tax/getTaxBatchList',
            createTaxBatch: 'tax/createTaxBatch',
            distributeTaxDeductionBatch: 'tax/distributeTaxDeductionBatch',
            deleteTaxBatch: 'tax/deleteTaxBatch'
        }),
        async getTaxList() {
            try {
                this.tax_deduction_batch_list = await this.getTaxBatchList(this.meta);
            } catch (error) {
                notificationDanger(error);
            }
        },
        async createTemplate() {
            try {
                await window.UIkit.modal('#create-template').show();
                this.fillForm();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doCreateTemplate() {
            try {
                this.button_loading = true;
                const response = await this.createTaxBatch(this.form);
                if (response && response.status === 'OK') {
                    await this.hideCreateTemplateModal();
                    await this.getTaxList();
                    notificationSuccess(`${response.result.name} tax batch created successfully`);
                } else {
                    notificationDangerCustom(`Failed to create tax deduction batch!`);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.button_loading = false;
            }
        },
        async hideCreateTemplateModal() {
            try {
                await window.UIkit.modal('#create-template').hide();
                this.resetForm();
            } catch (error) {
                notificationDanger(error);
            }
        },
        goToTaxDetail({ taxId }) {
            this.$router.push(`/admin/workers/tax/detail/${taxId}`);
        },
        async deleteTemplate({ taxId }) {
            try {
                await window.UIkit.modal('#delete-tax-deduction').show();
                this.selected_tax_deduction_batch_id = taxId;
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doDeleteTax() {
            try {
                this.button_delete_loading = true;
                const response = await this.deleteTaxBatch(this.selected_tax_deduction_batch_id);
                if (response && response.status === 'OK') {
                    await this.hideDeleteTaxModal();
                    await this.getTaxList();
                    notificationSuccess('Tax batch successfully removed');
                } else {
                    notificationDangerCustom('Failed to delete tax batch');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.button_delete_loading = false;
            }
        },
        async hideDeleteTaxModal() {
            try {
                await window.UIkit.modal('#delete-tax-deduction').hide();
                this.selected_tax_deduction_batch_id = null;
            } catch (error) {
                notificationDanger(error);
            }
        },
        async distributeBatch({ taxId }) {
            try {
                this.selected_tax_deduction_batch_id = taxId;
                await this.doDistributeBatch();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doDistributeBatch() {
            try {
                await window.UIkit.modal('#processing-modal').show();
                const response = await this.distributeTaxDeductionBatch(this.selected_tax_deduction_batch_id);
                if (response && response.status === 'OK') {
                    this.selected_tax_deduction_batch_id = null;
                    await this.getTaxList();
                    notificationSuccess('Tax batch blasted successfully');
                } else {
                    notificationDangerCustom('Failed to blast tax batch');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal('#processing-modal').hide();
            }
        },
        fillForm() {
            this.form = {
                name: null,
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear(),
                type: null,
                month_end: null,
            };
        },
        resetForm() {
            this.form = {
                name: null,
                month: null,
                year: null,
                type: null,
                month_end: null,
            };
        },
        monthFormatter({ index }) {
            return formatter.getMonthString({ index });
        },
        setNumberList({ index }) {
            if(this.tax_deduction_batch_list !== null) {
                const number = index + 1;
                if(this.tax_deduction_batch_list.page === 1) {
                    return number;
                } else {
                    if(number !== 10) {
                        return `${this.tax_deduction_batch_list.page - 1}${number}`;
                    } else {
                        return `${number * this.tax_deduction_batch_list.page}`;
                    }
                } 
            } else {
                return '-';
            }
        },
        generateArrayOfYears() {
            const max = new Date().getFullYear();
            const min = max - 9;
            const years = [];
            for(let i = max; i >= min; i--) {
                if(i >= 2020) {
                    years.push(i);
                }
            }
            this.year_list = years;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        }
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
}
th {
    color: #ffffff;
}
td {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.myrobin-color-blue {
    color: #0275d8;
}
</style>
