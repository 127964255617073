<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable main">
        <button class="uk-button uk-button-default uk-margin-bottom" @click="goBack">
            <span class="uk-margin-small-right" uk-icon="icon: arrow-left"></span>
            <span>Back</span>
        </button>

        <LoaderPage v-if="isLoading" />
        <template v-else-if="$can('READ', 'worker-tax-deduction')">
            <!-- Show page tab and template if tax batch is monthly -->
            <template v-if="taxBatchDetail && (!taxBatchDetail.type || taxBatchDetail.type === 0)">
                <!-- Tab button -->
                <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-1-4">
                        <button
                            type="button"
                            class="uk-button uk-button-default uk-border-rounded uk-width-1-1"
                            :class="[activeTab === 'tax_deduction_component' ? 'myrobin-background-primary myrobin-color-white' : 'myrobin-background-white myrobin-border-grey myrobin-color-grey']"
                            :disabled="activeTab === 'tax_deduction_component'"
                            @click="setActiveTabMenu('tax_deduction_component')"
                        >Tax Deduction Template</button>
                    </div>
                    <div class="uk-width-1-4">
                        <button
                            type="button"
                            class="uk-button uk-button-default uk-border-rounded uk-width-1-1"
                            :class="[activeTab === 'mitra_tax_list' ? 'myrobin-background-primary myrobin-color-white' : 'myrobin-background-white myrobin-border-grey myrobin-color-grey']"
                            :disabled="activeTab === 'mitra_tax_list'"
                            @click="setActiveTabMenu('mitra_tax_list')"
                        >Detail Tax Deduction Mitra</button>
                    </div>
                </div>
                <!-- End tab button -->

                <!-- Tab content -->
                <div class="uk-width-1-1 uk-margin-top">
                    <TaxDeductionComponent
                        v-if="activeTab === 'tax_deduction_component'"
                        :download-tax-batch-template-loading="downloadTaxBatchTemplateLoading"
                        @download-tax-batch-template="doDownloadTaxBatchTemplate('template')"
                    />
                    <MitraTaxList
                        v-if="activeTab === 'mitra_tax_list'"
                        :mitra-tax-list="mitraTaxList"
                        :table-loading="tableLoading"
                        :download-report-loading="downloadReportLoading"
                        @get-tax-batch-data="getTaxBatchData"
                        @filter-mitra-tax-list="filterMitraTaxList"
                        @download-tax-report-excel="doDownloadTaxBatchTemplate('report')"
                        @delete-worker-tax="deleteWorkerTax"
                        @preview-worker-tax="previewWorkerTaxProof"
                        @distribute-worker-tax="onDistributeWorkerTaxProof"
                        @change-limit="changeLimit"
                        @change-page="changePage"
                    />
                </div>
                <!-- End tab content -->
            </template>

            <!-- show mitra list if tax batch is new monthly -->
            <template v-else-if="taxBatchDetail && taxBatchDetail.type === 2">
                <div class="uk-width-1-1 uk-margin-top">
                    <MitraTaxListNewMonthly
                        :mitra-tax-list="mitraTaxList"
                        :company-name="taxBatchDetail.company_id.name"
                        :tax-batch-name="taxBatchDetail.name"
                        :tax-month-start="taxBatchDetail.month"
                        :tax-batch-year="taxBatchDetail.year"
                        :table-loading="tableLoading"
                        @get-tax-batch-data="getTaxBatchData"
                        @filter-mitra-tax-list="filterMitraTaxList"
                        @delete-worker-tax="deleteWorkerTax"
                        @preview-worker-tax="previewWorkerTaxProofMultiFile"
                        @distribute-worker-tax="onDistributeWorkerTaxProof"
                        @change-limit="changeLimit"
                        @change-page="changePage"
                    />
                </div>
                <!-- End tab content -->
            </template>

            <!-- show mitra list if tax batch is yearly -->
            <template v-else>
                <div class="uk-width-1-1 uk-margin-top">
                    <MitraTaxListYearly
                        :mitra-tax-list="mitraTaxList"
                        :company-name="taxBatchDetail.company_id.name"
                        :tax-batch-name="taxBatchDetail.name"
                        :tax-month-start="taxBatchDetail.month"
                        :tax-month-end="taxBatchDetail.month_end"
                        :tax-batch-year="taxBatchDetail.year"
                        :table-loading="tableLoading"
                        @get-tax-batch-data="getTaxBatchData"
                        @filter-mitra-tax-list="filterMitraTaxList"
                        @delete-worker-tax="deleteWorkerTax"
                        @preview-worker-tax="previewWorkerTaxProof"
                        @distribute-worker-tax="onDistributeWorkerTaxProof"
                        @change-limit="changeLimit"
                        @change-page="changePage"
                    />
                </div>
                <!-- End tab content -->
            </template>

            <!-- Confirmation modal box -->
            <div id="confirmation-modal-box" class="confirmation-modal-box uk-flex-top" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close @click="hideConfirmationModalBox"></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title">{{ confirmationModalBoxData.title || '-' }}</h2>
                    </div>
                    <div class="uk-modal-body">
                        <p>{{ confirmationModalBoxData.message || '-' }}</p>
                    </div>
                    <div class="uk-modal-footer">
                        <div class="uk-flex uk-flex-middle uk-flex-right gap-10px">
                            <LoaderButton v-if="confirmationModalBoxButtonLoading" />
                            <template v-else>
                                <button
                                    type="button"
                                    class="uk-button uk-button-default myrobin-border-blue myrobin-color-blue"
                                    @click="hideConfirmationModalBox"
                                >No</button>
                                <button
                                    type="button"
                                    class="uk-button uk-button-primary"
                                    @click="doConfirmation"
                                >Yes</button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End confirmation modal box -->

            <!-- Loading process modal box -->
            <div id="processing-modal" class="processing-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog">
                    <div class="uk-modal-body">
                        <div class="uk-flex uk-flex-column uk-flex-middle uk-flex-center">
                            <div uk-spinner="ratio: 2"></div>
                            <div class="uk-margin-top">Please wait...</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End loading process modal box -->

            <PreviewPDFModal
                v-if="showPreviewPDFModal"
                pageTitle="Tax Deduction Proof Preview"
                :url="pdfSrc"
                @hideModal="hidePreviewWorkerTaxProofModal()"
                @download-pdf="downloadWorkerTaxProof()"
            />

            <PreviewPDFMultiFileModal
                v-if="showPreviewPDFModalMultiFile"
                pageTitle="Tax Deduction Proof (Multi File) Preview"
                :filesUrl="filesUrl"
                @hideModal="hidePreviewWorkerTaxProofModal()"
                @download-pdf="downloadWorkerTaxProofMultiFile()"
            />
        </template>

        <!-- Handler when user can't access -->
        <div v-else>
            <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                    <span class="uk-text-large uk-text-bold">
                        <p>You are not authorized for read data this page</p>
                    </span>
                </div>
            </div>
        </div>
        <!-- End handler when user can't access -->

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import formatter from '@/utils/formatter';
import {
    notificationSuccess,
    notificationDangerCustom,
    notificationDanger
} from '@/utils/notification';
import { DateTime } from 'luxon';
import { excelDownloader, downloadAsZip } from '@/utils/helper';

export default {
    name: 'TaxBatchDetail',
    components: {
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        TaxDeductionComponent: () => import('@/components/globals/Tax/TaxDeductionComponent'),
        MitraTaxList: () => import('@/components/globals/Tax/MitraTaxList'),
        MitraTaxListYearly: () => import('@/components/globals/Tax/MitraTaxListYearly'),
        MitraTaxListNewMonthly: () => import('@/components/globals/Tax/MitraTaxListNewMonthly'),
        LoaderButton: () => import('@/components/globals/LoaderButton'),
        PreviewPDFModal: () => import('@/components//globals/modals/PdfViewerV2'),
        PreviewPDFMultiFileModal: () => import('@/components//globals/modals/PdfViewerMultiFile'),
    },
    data() {
        return {
            datetime: DateTime,
            isLoading: false,
            activeTab: 'tax_deduction_component',
            tableLoading: false,
            mitraTaxList: null,
            taxBatchDetail: null,
            meta: {
                limit: 10,
                page: 1,
                id: this.$route.params.id,
                worker_name: ''
            },
            downloadTaxBatchTemplateLoading: false,
            actionTypeModalBox: null,
            formField: {
                type: null,
                name: null
            },
            selectedField: {
                type_label: null,
                type_value: null,
                field_name: null
            },
            confirmationModalBoxData: {
                title: null,
                message: null
            },
            confirmationModalBoxButtonLoading: false,
            uploadTemplateLoading: false,
            downloadReportLoading: false,
            showPreviewPDFModal: false,
            pdfSrc: '',
            showPreviewPDFModalMultiFile: false,
            filesUrl: [],
            formAddWorker: {
                email: null,
                fullname: null,
                identity_number: null,
                address: null,
                tax_deduction_number: null,
                npwp: null,
                is_without_npwp: false,
                is_overseas_wp: false,
                country_code: null,
                tax_code: null,
                gross_salary: null,
                tax_rate: null,
                tax_dpp: null,
                pph_deduction: null,
                tax_deduction_date: null,
            },
            selectedWorkerTax: {
                tax_id: null,
                email: null,
                fullname: null,
                identity_number: null,
                address: null,
                tax_deduction_number: null,
                npwp: null,
                is_without_npwp: false,
                is_overseas_wp: false,
                country_code: null,
                tax_code: null,
                gross_salary: null,
                tax_rate: null,
                tax_dpp: null,
                pph_deduction: null,
                tax_deduction_date: null,
            },
        };
    },
    computed: {
        disabledButtonAddMitra() {
            if (
                this.formAddWorker.email === null ||
                this.formAddWorker.fullname === null ||
                this.formAddWorker.identity_number === null ||
                this.formAddWorker.tax_deduction_number === null ||
                this.formAddWorker.address === null 
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
    watch: {
        async meta() {
            try {
                await this.getTaxBatchData();
            } catch (error) {
                notificationDanger(error);
            }
        },
        'formAddWorker.gross_salary': {
            handler: function(value) {
                this.formatText(value || 0, 'gross_salary');
            },
            immediate: true
        },
        'formAddWorker.tax_dpp': {
            handler: function(value) {
                this.formatText(value || 0, 'tax_dpp');
            },
            immediate: true
        },
        'formAddWorker.pph_deduction': {
            handler: function(value) {
                this.formatText(value || 0, 'pph_deduction');
            },
            immediate: true
        },
        'formAddWorker.npwp': { // uncheck "is_without_npwp" field if NPWP is present
            handler: function(value) {
                if (value) {
                    this.formAddWorker.is_without_npwp = false;
                }
            },
            immediate: true
        },
        'formAddWorker.is_without_npwp': { // if "is_without_npwp" is checked, empty the NPWP field
            handler: function(value) {
                if (value) {
                    this.formAddWorker.npwp = null;
                }
            },
            immediate: true
        },
    },
    async beforeMount() {
        if (window.UIkit.modal('#confirmation-modal-box')) window.UIkit.modal('#confirmation-modal-box').$destroy(true);
        if (window.UIkit.modal('#processing-modal')) window.UIkit.modal('#processing-modal').$destroy(true);
        try {
            this.isLoading = true;
            await this.getTaxBatchData();

            if (this.taxBatchDetail && !this.taxBatchDetail.type) {
                this.setFirstActiveTabMenu();
            }
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getTaxBatchDetail: 'tax/getTaxBatchDetail',
            distributeMitraTaxProof: 'tax/distributeMitraTaxProof',
            distributeMitraTaxProofByUserId: 'tax/distributeMitraTaxProofByUserId',
            downloadMitraTaxProof: 'tax/downloadMitraTaxProof',
            deleteMitraTax: 'tax/deleteMitraTax',
            deleteMitraTaxByUserId: 'tax/deleteMitraTaxByUserId',
            taxDataReportExcel: 'tax/taxDataReportExcel',
        }),
        async setFirstActiveTabMenu() {
            try {
                if (this.$route.query.tab === undefined) await this.setQueryURL('tax_deduction_component');
                this.activeTab = this.$route.query.tab;
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setActiveTabMenu(tab) {
            try {
                if (this.$route.query.tab !== undefined) await this.setQueryURL(tab);
                this.activeTab = tab;
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setQueryURL(tab) {
            try {
                await this.$router.replace({
                    path: `/admin/workers/tax/detail/${this.$route.params.id}`,
                    query: { tab }
                });
            } catch (error) {
                notificationDanger(error);
            }
        },
        async getTaxBatchData() {
            try {
                this.tableLoading = true;
                const result = await this.getTaxBatchDetail(this.meta);
                this.taxBatchDetail = result;                
                this.mitraTaxList = result.taxes;
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        async filterMitraTaxList(payloads) {
            try {
                this.meta.batch_id = this.$route.params.id;
                this.meta.limit = 10;
                this.meta.page = 1;
                this.meta.worker_name = payloads;
                await this.getTaxBatchData();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doDownloadTaxBatchTemplate(type) {
            try {
                this.downloadTaxBatchTemplateLoading = true;

                let dataExcel = [];
                let obj = {};

                let file_name = "", templateType = "";
                const companyName = this.taxBatchDetail.company_id.name;
                const taxBatchName = this.taxBatchDetail.name;
                const month = this.monthFormatter({ index: this.taxBatchDetail.month });
                const year = this.taxBatchDetail.year;

                if (type == 'report') {
                    const result = await this.taxDataReportExcel(this.meta);
                    dataExcel = result;
                    templateType = "Report Bukti Potong";
                } else {
                    obj = {
                        "User ID": null,
                        "Email": null,
                        "Nomor Bukti Potong": null,
                        "NPWP": null,
                        "NIK": null,
                        "Nama": null,
                        "Alamat": null,
                        "WP Luar Negeri": null,
                        "Kode Negara": null,
                        "Kode Pajak": null,
                        "Jumlah Bruto": null,
                        "Jumlah DPP": null,
                        "Tanpa NPWP": null,
                        "Tarif": null,
                        "Jumlah PPh": null,
                        "Tanggal Bukti Potong": null,
                    };

                    dataExcel.push(obj);
                    templateType = "Bukti Potong";
                }

                file_name = `${templateType}_${companyName}_${taxBatchName}_${month}_${year}.xlsx`;
                excelDownloader(dataExcel, file_name);
                notificationSuccess(`Tax ${ type == 'report' ? 'report' : 'template' } downloaded successfully`);
            } catch (error) {
                notificationDanger(error);
                notificationDangerCustom(`Failed to download tax ${ type == 'report' ? 'report' : 'template' }`);
            } finally {
                this.downloadTaxBatchTemplateLoading = false;
            }
        },
        async showConfirmationModalBox({ title, message }) {
            try {
                this.confirmationModalBoxData.title = title;
                this.confirmationModalBoxData.message = message;
                await window.UIkit.modal('#confirmation-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideConfirmationModalBox() {
            try {
                this.actionTypeModalBox = null;
                this.confirmationModalBoxData.title = null;
                this.confirmationModalBoxData.message = null;
                await window.UIkit.modal('#confirmation-modal-box').hide();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doConfirmation() {
            try {
                this.confirmationModalBoxButtonLoading = true;

                if (this.actionTypeModalBox === 'delete-mitra-tax') {
                    await this.doDeleteWorkerTax();
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.confirmationModalBoxButtonLoading = false;
                await this.hideConfirmationModalBox();
            }
        },
        async deleteWorkerTax({ action, mitraTax }) {
            try {
                this.actionTypeModalBox = action;
                this.resetSelectedWorkerTax();

                if (this.taxBatchDetail && this.taxBatchDetail.type == 2) {
                    const { _id } = mitraTax;
                    this.selectedWorkerTax.batch_id = this.taxBatchDetail._id;
                    this.selectedWorkerTax.user_id = _id;
                } else {
                    const { tax_deduction_batch_id, _id } = mitraTax;
                    this.selectedWorkerTax.batch_id = tax_deduction_batch_id;
                    this.selectedWorkerTax.tax_id = _id;
                }

                await this.showConfirmationModalBox({
                    title: 'Confirmation', 
                    message: 'Are you sure want to delete this entry?'
                });
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doDeleteWorkerTax() {
            try {
                let response = null;
                if (this.taxBatchDetail.type == 2) {
                    response = await this.deleteMitraTaxByUserId(this.selectedWorkerTax);
                } else {
                    response = await this.deleteMitraTax(this.selectedWorkerTax);
                }
                if (response && response.status === 'OK' && response.result === 'success') {
                    this.actionTypeModalBox = null;
                    this.resetSelectedWorkerTax();

                    notificationSuccess('Mitra tax deleted successfully');
                } else {
                    notificationDangerCustom('Failed to delete mitra tax');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await this.getTaxBatchData();
            }
        },
        async previewWorkerTaxProof({ mitraTax }) {
            try {
                this.resetSelectedWorkerTax();
                await window.UIkit.modal('#processing-modal').show();

                const { tax_deduction_batch_id, _id } = mitraTax;
                this.selectedWorkerTax.batch_id = tax_deduction_batch_id;
                this.selectedWorkerTax.tax_id = _id;

                const response = await this.downloadMitraTaxProof(this.selectedWorkerTax);
                if (response && response.status === 'OK') {
                    await window.UIkit.modal('#processing-modal').hide();
                    this.showPreviewPDFModal = true;
                    this.pdfSrc = response.result.file_url;
                } else {
                    notificationDangerCustom('Failed to preview tax proof!');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal('#processing-modal').hide();
            }
        },
        async previewWorkerTaxProofMultiFile({ mitraTax }) {
            try {
                this.resetSelectedWorkerTax();
                await window.UIkit.modal('#processing-modal').show();

                this.filesUrl = mitraTax.files ? mitraTax.files.map(x => x.file_url) : [];

                if (this.filesUrl.length > 0) {
                    this.showPreviewPDFModalMultiFile = true;
                } else {
                    notificationDangerCustom('Failed to preview tax proof!');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal('#processing-modal').hide();
            }
        },
        async hidePreviewWorkerTaxProofModal() {
            try {
                this.resetSelectedWorkerTax();
                this.showPreviewPDFModal = false;
                this.showPreviewPDFModalMultiFile = false;
            } catch (error) {
                notificationDanger(error);
            }
        },
        async downloadWorkerTaxProof() {
            this.showPreviewPDFModal = false;
            window.open(this.pdfSrc, "_blank");
        },
        async downloadWorkerTaxProofMultiFile() {
            this.showPreviewPDFModalMultiFile = false;
            await downloadAsZip(this.filesUrl);
        },
        resetFormAddWorker() {
            this.formAddWorker = {
                email: null,
                fullname: null,
                identity_number: null,
                address: null,
                tax_deduction_number: null,
                npwp: null,
                is_without_npwp: false,
                is_overseas_wp: false,
                country_code: null,
                tax_code: null,
                gross_salary: null,
                tax_rate: null,
                tax_dpp: null,
                pph_deduction: null,
                tax_deduction_date: null,
            };
        },
        resetSelectedWorkerTax() {
            this.selectedWorkerTax = {
                tax_id: null,
                email: null,
                fullname: null,
                identity_number: null,
                address: null,
                tax_deduction_number: null,
                npwp: null,
                is_without_npwp: false,
                is_overseas_wp: false,
                country_code: null,
                tax_code: null,
                gross_salary: null,
                tax_rate: null,
                tax_dpp: null,
                pph_deduction: null,
                tax_deduction_date: null,
            };
        },
        async onDistributeWorkerTaxProof({ action, mitraTax }) {
            try {
                if (this.taxBatchDetail.type == 2) {
                    const { _id } = mitraTax;
                    this.selectedWorkerTax.batch_id = this.taxBatchDetail._id;
                    this.selectedWorkerTax.user_id = _id;
                } else {
                    const { tax_deduction_batch_id, _id } = mitraTax;
                    this.selectedWorkerTax.batch_id = tax_deduction_batch_id;
                    this.selectedWorkerTax.tax_id = _id;
                }
                this.actionTypeModalBox = action;
                await window.UIkit.modal('#processing-modal').show();
                await this.doDistributeWorkerTaxProof();
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal('#processing-modal').hide();
            }
        },
        async doDistributeWorkerTaxProof() {
            try {
                let response = null;
                if (this.taxBatchDetail.type == 2) {
                    response = await this.distributeMitraTaxProofByUserId(this.selectedWorkerTax);                    
                } else {
                    response = await this.distributeMitraTaxProof(this.selectedWorkerTax);
                }

                if (response && response.status === 'OK' && response.result === 'success') {
                    if (this.taxBatchDetail.type != 2) {
                        const index = this.mitraTaxList.docs.findIndex((tax) => tax._id == this.selectedWorkerTax.tax_id);
                        this.mitraTaxList.docs[index].is_distributed = true;
                    }

                    this.actionTypeModalBox = null;
                    this.resetSelectedWorkerTax();

                    notificationSuccess('Mitra tax successfully blasted');
                } else {
                    notificationDangerCustom('Failed to send mitra tax');
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        formatText(value=0, formName) {
            const balance = value.toString().replace(/[^0-9]/g, '');
            this.formAddWorker[formName] = formatter.thousandSeparator(balance);
        },
        monthFormatter({ index }) {
            return formatter.getMonthString({ index });
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        goBack() {
            this.$router.back();
        },
    }
};
</script>

<style scoped>
.myrobin-background-primary {
    background-color: #0ABAB5;
}
.myrobin-color-white {
    color: #FFFFFF;
}
.myrobin-color-grey {
    color: #9A9A9A;
}
.gap-10px {
    gap: 10px;
}
.gap-20px {
    gap: 20px;
}
</style>
